import React, { useEffect } from "react"
import { getPropertyAction } from "../../redux/propertiesDucks"
import { navigate } from "gatsby"  
import { makeLink } from "../../helpers/helper.properties"
import Loading from "../../components/Loading"
import { connect } from "react-redux"
import {CHANGE_APIKEY_ACTION} from '../../redux/settingsDucks'
import { useStaticQuery, graphql } from "gatsby"

const Redirect = ({property,loading,id,api_key,dispatch}) =>{

  const { realEstate } = useStaticQuery(graphql`
  query {
      realEstate {
          keys{
            tokko
          }
      }
  }`)

  useEffect(() => {
      dispatch(CHANGE_APIKEY_ACTION(realEstate?.keys?.tokko))
  },[realEstate])

  useEffect(() => {
    if(api_key){
      dispatch(getPropertyAction(id))
    }
  }, [api_key])
  return property?.id ? (
      <div>
          <Loading absolute={true}/>
          {navigate(makeLink(property))}        
      </div>
  )
  :<Loading absolute={true}/>
}

export default connect(state => ({
  loading: state.properties.loading,
  property:state.properties.property,
  api_key: state.settings.keys.tokko
}),null)(Redirect);
